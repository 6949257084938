import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import Card from "../entities/article/Card";
import { useParams } from "react-router-dom";
import Animated from "../components/Animated";
import { mainApi } from "../providers/api";
import { ShowWarning } from "../providers/alert";

export const TAG_OPTIONS = [
	"article",
	"interview",
	"publishment",
	"photo",
	"video",
];

export const CATEGORY_OPTIONS = [
	"all",
	"metro",
	"brt",
	"cablecar",
	"greengate",
];

const Articles = () => {
	const { category } = useParams();
	const { t } = useTranslation();
	const [selected, setSelected] = useState(category || "all");

	const [tag, setTag] = useState();

	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

	const fetchItems = async (selected, tag) => {
		try {
			let find = {};

			if (selected !== "all") {
				find["category.value"] = selected;
			}

			if (tag) {
				find["tags.value"] = tag;
			}

			setLoading(true);
			const res = await mainApi({
				method: "POST",
				url: `/public/article/list`,
				data: {
					find,
					offset: 0,
					limit: 60,
					sort: { _id: -1 },
				},
			});

			setItems(res.data);
		} catch (err) {
			ShowWarning(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchItems(selected, tag);
	}, [selected, tag]);

	return (
		<div className="bg-gray-100">
			<div className="container mx-auto p-2">
				{loading && <Loader></Loader>}
				<div className="animate-fade text-center my-10 uppercase text-2xl">
					<div className="mb-10">{t("articles")}</div>
				</div>
				<Animated>
					<div className="w-full flex-wrap rounded-lg mb-10 select-none shadow-md hidden">
						{CATEGORY_OPTIONS.map((category) => (
							<div
								key={category}
								className={`p-2 border flex-1 text-center uppercase min-w-32 cursor-pointer ${
									selected === category
										? `${category} active text-white`
										: "bg-white"
								}`}
								onClick={() => {
									setSelected(category);
								}}
							>
								{t(`categories.${category}`)}
							</div>
						))}
					</div>
					<div className="w-full flex flex-wrap rounded-lg mb-10 select-none shadow-md">
						{TAG_OPTIONS.map((_tag) => (
							<div
								key={_tag}
								className={`p-2 border flex-1 text-center uppercase min-w-32 cursor-pointer ${
									tag === _tag ? `${tag} active font-bold` : "bg-white"
								}`}
								onClick={() => {
									setTag(_tag === tag ? null : _tag);
								}}
							>
								{t(`tags.${_tag}`)}
							</div>
						))}
					</div>
				</Animated>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
					{items.map((item, index) => (
						<Card key={index} {...item}></Card>
					))}
				</div>
			</div>
		</div>
	);
};

export default Articles;
