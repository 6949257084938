const BiDashboard = () => {
	return (
		<iframe
			title="Power BI dashboard"
			className="mx-auto w-full border mb-5"
			height="600"
			src="https://app.powerbi.com/reportEmbed?reportId=ed3014b7-83de-42b7-a48f-006092f22133&autoAuth=true&ctid=59121926-3261-44a4-a5d2-6dcef2b87bba"
			frameborder="0"
			allowFullScreen="true"
		></iframe>
	);
};

export default BiDashboard;
