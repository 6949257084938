import "./index.css";
import "./providers/i18n";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import AppProvider from "./providers/app";

import PublicLayout from "./layouts/PublicLayout";
import AppLayout from "./layouts/AppLayout";
import AuthLayout from "./layouts/AuthLayout";

import Loader from "./pages/Loader";
import Home from "./pages/Home";
import About from "./pages/About";
import Metro from "./pages/Metro";
import Brt from "./pages/Brt";
import CableCar from "./pages/CableCar";
import GreenGate from "./pages/GreenGate";
import Transparency from "./pages/Transparency";

import Articles from "./pages/Articles";
import Article from "./pages/Article";

import Login from "./pages/Login";
import Profile from "./pages/Profile";

import UserList from "./entities/user/List";
import UserForm from "./entities/user/Form";

import ArticleList from "./entities/article/List";
import ArticleForm from "./entities/article/Form";

import TransparencyList from "./entities/transparency/List";
import TransparencyForm from "./entities/transparency/Form";
import BiDashboard from "./pages/BiDashboard";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
		<React.StrictMode>
			<HelmetProvider>
				<Suspense fallback={<Loader />}>
					<AppProvider>
						<Routes>
							<Route path="/" element={<Navigate to="/home" />} />
							<Route path="/" element={<PublicLayout />}>
								<Route path="home" element={<Home />} />
								<Route path="about" element={<About />} />
								<Route path="metro" element={<Metro />} />
								<Route path="brt" element={<Brt />} />
								<Route path="cablecar" element={<CableCar />} />
								<Route path="greengate" element={<GreenGate />} />
								<Route path="transparency" element={<Transparency />} />
								<Route path="articles" element={<Articles />} />
								<Route path="powerbi" element={<BiDashboard />} />
								<Route path=":category/articles" element={<Articles />} />
								<Route path=":category/articles/:id" element={<Article />} />
							</Route>
							<Route path="auth" element={<AuthLayout />}>
								<Route path="login" element={<Login />} />
								<Route path="logout" element={<Login from="inside" />} />
							</Route>
							<Route path="app" element={<AppLayout />}>
								<Route path="profile" element={<Profile />} />

								<Route path="article" element={<ArticleList />} />
								<Route path="article/new" element={<ArticleForm />} />
								<Route
									path="article/view/:article_id"
									element={<ArticleForm />}
								/>

								<Route path="transparency" element={<TransparencyList />} />
								<Route path="transparency/new" element={<TransparencyForm />} />
								<Route
									path="transparency/view/:transparency_id"
									element={<TransparencyForm />}
								/>

								<Route path="user" element={<UserList />} />
								<Route path="user/new" element={<UserForm />} />
								<Route path="user/view/:user_id" element={<UserForm />} />
							</Route>
						</Routes>
					</AppProvider>
				</Suspense>
			</HelmetProvider>
		</React.StrictMode>
	</BrowserRouter>
);
