import { useTranslation } from "react-i18next";
import Animated from "../components/Animated";
import { Title } from "../components/Project";

const YEARS = [
	{
		color: "red",
		year: "2021 он",
		tasks: [
			<>
				Монгол Улсын Засгийн газрын 2021 оны 146 дугаар тогтоолын 2 дугаар зүйлд
				“Нийслэл Улаанбаатар хотын авто замын түгжрэлийг бууруулах, нийтийн
				тээврийн үйлчилгээний чанарыг сайжруулах, хүртээмжийг нэмэгдүүлэх
				чиглэлээр гадаадын зээл, тусламжийн төсөл арга хэмжээг авч
				хэрэгжүүлэх”-ээр тусгав. Өөрөөр хэлбэл, улс, нийслэлийн төсөв болон
				гадаадын зээл тусламжийн хөрөнгөөр хэрэгжүүлж буй төсөл, арга хэмжээнээс
				Улаанбаатар хотын авто замын{" "}
				<strong>түгжрэлийг бууруулах чиглэлээр</strong> хэрэгжүүлж буй төсөл,
				хөтөлбөрийг тусгайлан авч үзэх болов.
			</>,
			<>
				Монгол Улсын Засгийн газрын 2021 оны 12 дугаар сарын 01-ний өдрийн{" "}
				<strong>360 дугаар тогтоолоор</strong> “Нийслэлийн замын түгжрэлийг
				бууруулахад чиглэсэн төсөл хэрэгжүүлэх нэгж”-ийг зохих журмын дагуу
				Нийслэлийн Засаг дарга бөгөөд Улаанбаатар хотын Захирагчийн дэргэд
				байгуулж ажиллуулах”-ыг үүрэг болгов.
			</>,
			<>
				НИТХ-ын тэргүүлэгчдийн 2021 оны 12 дугаар сарын 20-ны өдрийн{" "}
				<strong>180 дугаар тогтоолоор</strong> улс, нийслэлийн төсвийн
				санхүүжилттэй болон гадаадын зээл, хөрөнгө оруулалттай төсөл,
				хөтөлбөрийн үйл ажиллагааг хэрэгжүүлж, нэгдсэн удирдлагаар хангах чиг
				үүрэг бүхий “Нийслэл Улаанбаатар хотын авто замын түгжрэлийг бууруулах
				нэгдсэн төсөл хэрэгжүүлэх нэгж” <strong>УТҮГ-ыг байгуулав.</strong>
			</>,
		],
	},
	{
		color: "green",
		year: "2022 он",
		tasks: [
			<>
				НИТХ-ын Тэргүүлэгчдийн 2022 оны 03 дугаар сарын 21-ний өдрийн 28 дугаар
				тогтоолоор “Нийслэл Улаанбаатар хотын авто замын түгжрэлийг бууруулах
				нэгдсэн төсөл хэрэгжүүлэх нэгж” УТҮГ-ын{" "}
				<strong>дүрмийг шинэчлэн батлав.</strong>
			</>,
			<>
				Монгол Улсын Засгийн газар, БНФУ-ын Засгийн газар хооронд байгуулсан
				санхүүгийн хэлэлцээр (2020.05.12), УИХ-аас соёрхон баталсан хууль
				(2020.05.14)-ийн дагуу Агаарын дүүжин замын тээврийн төсөл хэрэгжүүлэх
				шийдвэр гарсан. Улмаар Нийслэлийн Засаг даргын 2022 оны{" "}
				<strong>А/313 дугаар захирамжаар</strong> БНФУ-ын Засгийн газрын
				санхүүжилтээр хэрэгжүүлж буй “Нийслэлийн нийтийн тээвэрт дүүжин замын
				тээврийг нэвтрүүлэх төсөл”-ийг “Нийслэл Улаанбаатар хотын авто замын
				түгжрэлийг бууруулах нэгдсэн төсөл хэрэгжүүлэх нэгж” УТҮГ хариуцан
				хэрэгжүүлж эхлэв.
			</>,
			<>
				Монгол Улсын Их хурал, Засгийн газрын тогтоол, шийдвэрийг үндэслэн
				байгуулсан “Хөгжлийн хөтөч-дэд бүтэц” төслийн (2020.12.25) 598 дугаар
				тоотын дагуу авто замын нүхэн гарц барих байршлыг тогтоосон. Улмаар
				Нийслэлийн Засаг даргын 2022 оны{" "}
				<strong>А/1476 дугаар захирамжаар</strong> БНХАУ-ын Засгийн газрын
				хөнгөлөлттэй зээлийн хүрээнд хэрэгжүүлж буй “Улаанбаатар хотын төмөр
				замын доогуур авто замын нүхэн гарц барих төсөл”-ийг “Нийслэл
				Улаанбаатар хотын авто замын түгжрэлийг бууруулах нэгдсэн төсөл
				хэрэгжүүлэх нэгж” УТҮГ-д хариуцуулав.
			</>,
		],
	},
	{
		color: "yellow",
		year: "2023 он",
		tasks: [
			<>
				Монгол Улсын Засгийн газрын 2023 оны 11 дүгээр сарын 22-ны өдрийн{" "}
				<strong>411 дүгээр тогтоолоор</strong> “Нийслэл Улаанбаатар хотод Их
				багтаамжийн нийтийн тээврийн хэрэгсэл Метро байгуулах” тухай шийдвэр
				гарч, 2024-2028 онд багтаан барьж байгуулах, техник, эдийн засгийн
				үндэслэл, зураг, төсөл, нарийвчилсан төлөвлөгөө иж бүрдэл боловсруулах
				олон улсын зөвлөх үйлчилгээний сонгон шалгаруулалтыг эхлүүлэхээр болсон.
				Үүний дагуу Нийслэлийн Засаг даргын{" "}
				<strong>А/190 тоот захирамжаар</strong> ажлын хэсэг байгуулан ажиллаж
				байна.
			</>,
		],
	},
	{
		color: "blue",
		year: "2024 он",
		tasks: [
			<>
				“Улаанбаатар хотод их багтаамжийн нийтийн тээврийн хэрэгсэл буюу метро
				барих төслийн зөвлөх үйлчилгээ үзүүлэх олон улсын нээлттэй тендер
				зарлагдаж, нэгдүгээр үе шатанд 11 орны 20 компани саналаа ирүүлэв.
				Үүнээс БНСУ-ын “Дова инженеринг” компани шалгарч, 2024 оны 07 дугаар
				сарын 05-ны өдөр талууд <strong>“Улаанбаатар метро”</strong> төслийн
				зөвлөх үйлчилгээний гэрээг байгуулав.
			</>,
			<>
				Монгол Улс болон Азийн хөгжлийн банк хооронд{" "}
				<strong>“Тусгай замын автобус төсөл”</strong>-ийг (BRT) Улаанбаатар
				хотын авто замын сүлжээний хөндлөн чиглэлд барих санамж бичиг байгуулсан
				бөгөөд УИХ-аар соёрхон баталж, Монгол Улсын Засгийн газрын (2023.08.23)
				тогтоолд тус төслийг 2024-2026 онд хэрэгжүүлэхээр тусгасан. Үүний дагуу
				2024 оны 06 дугаар сарын 19-ний өдрийн Нийслэлийн Засаг даргын А/718
				тоот захирамжаар “Тусгай замын автобус BRT төсөл”-ийн ажлыг “Нийслэл
				Улаанбаатар хотын авто замын түгжрэлийг бууруулах НТХН”-д даалгав.
			</>,
			<>
				Монгол Улсын Засгийн газрын <strong>“Алсын хараа-2050”</strong> урт
				хугацааны хөгжлийн бодлого, Улаанбаатар хотыг 2040 он хүртэл хөгжүүлэх
				ерөнхий төлөвлөгөөний концепц, Нийслэлийн Засаг дарга бөгөөд Улаанбаатар
				хотын Захирагчийн 2024-2028 оны үйл ажиллагааны хөтөлбөрт Улаанбаатар
				хотын нийтийн тээврийн системд хөнгөн галт тэрэг “Трамвай” нэвтрүүлэх
				төслийг хэрэгжүүлэхээр тусгасан. Үүний дагуу Нийслэлийн Засаг даргын
				2024 оны 11 дүгээр сарын 15-ны өдрийн{" "}
				<strong>А/1370 дугаар захирамжаар</strong> хөнгөн галт тэрэг “Трамвай”
				нэвтрүүлэх төсөл ажлыг “Нийслэл Улаанбаатар хотын авто замын түгжрэлийг
				бууруулах НТХН”-д даалгав.
			</>,
			<>
				“Монгол Улсын Засгийн газрын 2024-2028 оны үйл ажиллагааны хөтөлбөр”,
				Нийслэлийн Засаг дарга бөгөөд Улаанбаатар хотын захирагчийн 2024-2028
				оны үйл ажиллагааны хөтөлбөр, <strong>“20 минутын хот”</strong>{" "}
				концепцид тусгасны дагуу Туулын хурдны зам болон Шинэ тойрог замын төсөл
				ажлыг “Нийслэл Улаанбаатар хотын авто замын түгжрэлийг бууруулах НТХН”-д
				даалгав.
			</>,
			<>
				Их багтаамжийн нийтийн тээврийн хэрэгсэл “Метро” төсөл, Тусгай замын
				автобус BRT төсөл, “Шинэ тойрог авто зам” төсөл, “Хөнгөн галт тэрэг
				төсөл”-ийг тус тус хариуцан хэрэгжүүлэх болсонтой холбоотойгоор
				Нийслэлийн Засаг даргын 2024 оны <strong>А1339 захирамж</strong> гарч,
				бүтэц дүрмийг шинэчлэн батлав.
			</>,
		],
	},
	{
		color: "red",
		year: "2025 он",
		tasks: [
			<>
				Монгол Улсын Засгийн газраас 2025 оныг{" "}
				<strong>“Нийслэлийн бүтээн байгуулалтыг дэмжих жил"</strong> болгон
				зарласан. Энэ хүрээнд Нийслэлийн Засаг дарга бөгөөд Улаанбаатар хотын
				захирагч Х.Нямбаатар захирамж гаргаж, 2024-2028 оны үйл ажиллагааны
				хөтөлбөр, нийслэлийн хөгжлийн 2025 оны төлөвлөгөөний үндсэн чиглэлийг
				хэрэгжүүлэх зорилгоор 2025 оныг{" "}
				<strong>“Бүтээн байгуулалтын жил”</strong> болгон зарлав.
			</>,
		],
	},
];

const AboutPage = () => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="max-w-xl mx-auto bg-white px-2">
				<Title color="blue" title={t("ipiu-full")}></Title>
				<Animated animation="animate-fade-down text-center">
					“Нийслэл Улаанбаатар хотын авто замын түгжрэлийг бууруулах нэгдсэн
					төсөл хэрэгжүүлэх нэгж” УТҮГ нь авто замын түгжрэлийг бууруулах,
					төвлөрлийг сааруулах, нийтийн тээврийн чанар, хүртээмжийг сайжруулах
					чиглэлд нийслэлээс хэрэгжүүлэх төсөл, арга хэмжээний биелэлтийг
					шуурхай, үр дүнтэй хариуцан зохион байгуулж, Монгол Улсын хууль
					тогтоомж, стандартыг баримтлан төслүүдийн уялдаа холбоог хангаж, ил
					тод, нээлттэй ажиллана.
				</Animated>
				<Animated animation="animate-fade-down">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/about.jpg"
						className="w-full max-h-96 object-contain my-10 mb-20"
					/>
				</Animated>
				{YEARS.map(({ year, color, tasks }) => {
					return (
						<div key={year} className={`text-justify ${color} mb-20 about`}>
							<Title color={"black" || "color"} title={year}></Title>
							<div className="flex flex-col gap-10">
								{tasks.map((task, index) => (
									<Animated
										key={index}
										animation={
											index % 2 === 0 ? "animate-fade" : "animate-fade"
										}
									>
										{task}
									</Animated>
								))}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AboutPage;
