import { Trans, useTranslation } from "react-i18next";
import Animated from "../components/Animated";
import CableCar from "../components/CableCar";
import LatestArticles from "../components/LatestArticles";
import { Title } from "../components/Project";
import AutoPlayVideo from "../components/AutoPlayVideo";

const CableCarPage = () => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="relative overflow-hidden">
				<div className="container mx-auto">
					<Animated animation="animate-fade">
						<img
							alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
							src="/ub_cablecar.png"
							className="w-full h-auto z-10"
						/>
					</Animated>
				</div>
				<div className="w-full h-auto absolute top-0 left-0 -z-10">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/ub_cablecar.png"
						className="w-full blur-lg"
					/>
				</div>
			</div>
			<div className="container mx-auto bg-white px-2">
				<CableCar></CableCar>
			</div>
			<div className="bg-gray-100 py-10">
				<div className="container mx-auto px-2">
					<LatestArticles category="cablecar"></LatestArticles>
				</div>
			</div>
			<div className="container mx-auto px-2">
				<div className="">
					<Title color="blue" title={t("pros")}></Title>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-10 cablecar mb-10">
						<Animated animation="animate-fade-right">
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/cable1.png"
								className="h-[400px] w-full object-cover mb-2 rounded-lg"
							/>
							<Trans t={t}>cablecar.f1</Trans>
						</Animated>
						<Animated animation="animate-fade-left">
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/cable2.png"
								className="h-[400px] w-full object-cover mb-2 rounded-lg"
							/>
							<Trans t={t}>cablecar.f2</Trans>
						</Animated>
						<Animated animation="animate-fade-right">
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/cable3.png"
								className="h-[400px] w-full object-cover mb-2 rounded-lg"
							/>
							<Trans t={t}>cablecar.f3</Trans>
						</Animated>
						<Animated animation="animate-fade-left">
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/cable4.png"
								className="h-[400px] w-full object-cover mb-2 rounded-lg"
							/>
							<Trans t={t}>cablecar.f4</Trans>
						</Animated>
					</div>
				</div>
			</div>
			<div className="bg-gray-100 py-10 pb-20 px-2">
				<div className="container mx-auto">
					<div className="">
						<Title color="blue" title={t("cablecar.video")}></Title>
						<Animated>
							<AutoPlayVideo
								src="/video/cablecar.mp4"
								className="h-96 w-auto mx-auto rounded-lg"
								controls={true}
							></AutoPlayVideo>
						</Animated>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CableCarPage;
