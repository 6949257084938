import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { API_ROOT } from "../../defines";
import Animated from "../../components/Animated";
import { FaClock } from "react-icons/fa";
import Swal from "sweetalert2";
import { renderToString } from "react-dom/server";

const TransparencyCard = (article) => {
	const { t, i18n } = useTranslation();

	const id = objectPath.get(article, "attachment.0.id");
	const title = objectPath.get(article, `title.${i18n.language}`);
	const category = t(
		`transparency_cat.${objectPath.get(article, `category.value`)}`
	);

	if (!id) return null;

	const showPdf = () => {
		const url = API_ROOT + "/" + id;

		Swal.fire({
			html: renderToString(
				<embed src={url} type="application/pdf" width="100%" height="700px" />
			),
			showCloseButton: true,
			showCancelButton: false,
			showConfirmButton: false,
			width: "100%",
			animation: false,
		});
	};

	return (
		<Animated animation="animate-fade bg-white rounded-lg overflow-hidden shadow-md flex">
			<div className="relative flex flex-col flex-1 justify-between">
				<button
					className="h-[300px] w-full flex items-center justify-center text-5xl"
					onClick={() => {
						showPdf(id);
					}}
				>
					<img src="/icon.png" className="h-32 w-full object-contain" alt="" />
				</button>
				<div className="p-5 flex flex-col justify-between bg-white flex-1">
					<div className="text-sm">#{category}</div>
					<div className="font-bold uppercase">{title}</div>
					<div className="mt-5 flex gap-5 text-dark-70 justify-end">
						<div className="flex gap-2 items-center">
							<FaClock className="text-blue-100"></FaClock> {article._published}
						</div>
					</div>
				</div>
			</div>
		</Animated>
	);
};

export default TransparencyCard;
